import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Subscription } from "rxjs";

import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { MatchInfo } from "../../types/entities/match-info";
import { DeveloperRoute } from "../../types/route-helpers";
import { services, showMessage } from "../../types/services";
import {
  BetSource,
  betSourceReadableNames,
} from "../../types/stored-bets/bet-source";
import { LastStoredBetScrape } from "../../types/stored-bets/last-stored-bet-scrape";
import { StoredBet } from "../../types/stored-bets/stored-bet";
import { StoredGameStateOdds } from "../../types/stored-bets/stored-odds";
import { UUID } from "../../types/uuid";
import AppAlert from "../common-components/app-alert";
import PaginationButtons from "../common-components/pagination/pagination-buttons";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import BetHistoryDisplay from "./bet-history-display";
import BetSourceScrapeTitle from "./bet-source-scrape-title";

import { createCSVContent, createCSVFilename } from "./csv-utils";

export interface GetBetsResponse {
  bets: { [matchId: string]: StoredBet[] };
  odds: { [matchId: string]: StoredGameStateOdds[] };
  linksBySourceEventId: { [sourceEventId: string]: UUID };
  error: string;
  totalPages: number;
}

export default function BetsPage() {
  const [betfairUsername, setBetfairUsername] = useState<string>("");
  const [betfairPassword, setBetfairPassword] = useState<string>("");
  const [winner7Username, setWinner7Username] = useState<string>("");
  const [winner7Password, setWinner7Password] = useState<string>("");
  const [matches, setMatches] = useState<MatchInfo[]>([]);
  const [groupedBets, setGroupedBets] = useState<{
    [matchId: string]: StoredBet[];
  }>({});
  const [groupedOdds, setGroupedOdds] = useState<{
    [matchId: string]: StoredGameStateOdds[];
  }>({});
  const [linksBySourceEventId, setLinksBySourceEventId] = useState<{
    [sourceEventId: string]: UUID;
  }>({});
  const [showLogin, setShowLogin] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [requiredScrapes, setRequiredScrapes] = useState<BetSource[]>([]);
  const [alertsEnabled, setAlertsEnabled] = useState<Map<BetSource, boolean>>(
    new Map()
  );

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.betScraperService.lastStoredBetScrapeSubject.subscribe(
        (lastStoredBetScrape: LastStoredBetScrape) => {
          setRequiredScrapes(
            services.betScraperService.getRequiredScrapes(lastStoredBetScrape)
          );
          setAlertsEnabled(
            lastStoredBetScrape?.alertsEnabledBySource || new Map()
          );
        }
      ),
      services.matchService
        .getAllMatchesSubject()
        .subscribe((matches: MatchInfo[]) => {
          setMatches(matches);
          setLoading(false);
        }),
    ];

    services.matchService.getAllMatches();

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleError = useCallback((errorMessage: string) => {
    showMessage("Error getting bets: " + errorMessage);
    setGroupedBets({});
  }, []);

  const handleBetsUpdated = () => {
    setLoading(true);
    fetchBets(currentPage - 1, services.betScraperService.PAGE_SIZE);
    setLoading(false);
  };

  const fetchBets = useCallback(
    (page: number, size: number) => {
      setLoading(true);
      services.betScraperService
        .getBets(page, size)
        .then((data: GetBetsResponse) => {
          if (data.error) {
            handleError(data.error);
          } else {
            setGroupedBets(data.bets);
            setGroupedOdds(data.odds);
            setLinksBySourceEventId(data.linksBySourceEventId);
            setTotalPages(data.totalPages);
            if (Object.keys(data.bets).length > 0) {
              setShowLogin(false);
            }
          }
        })
        .catch((error) => {
          handleError(error.message);
        })
        .finally(() => setLoading(false));
    },
    [handleError]
  );

  useEffect(() => {
    fetchBets(0, services.betScraperService.PAGE_SIZE);
  }, [fetchBets]);

  useEffect(() => {
    fetchBets(currentPage - 1, services.betScraperService.PAGE_SIZE);
  }, [currentPage, fetchBets]);

  const scrapeBets = (source: string) => {
    setBetfairUsername("");
    setBetfairPassword("");
    setWinner7Username("");
    setWinner7Password("");
    setCurrentPage(1);
    setLoading(true);
    services.betScraperService
      .scrapeBets(
        source,
        source === "BETFAIR" ? betfairUsername : winner7Username,
        source === "BETFAIR" ? betfairPassword : winner7Password
      )
      .then((data: GetBetsResponse) => {
        if (data.error) {
          handleError(data.error);
        } else {
          setGroupedBets(data.bets);
          setGroupedOdds(data.odds);
          setLinksBySourceEventId(data.linksBySourceEventId);
          if (Object.keys(data.bets).length > 0) {
            setShowLogin(false);
          }
        }
      })
      .catch((error) => {
        handleError(error.message);
      })
      .finally(() => setLoading(false));
  };

  const rescrapeAll = () => {
    setLoading(true);
    services.betScraperService
      .reScrapeAllBets()
      .then(() => {
        showMessage("Successfully re-scraped all bets");
        handleBetsUpdated();
      })
      .catch((error) => {
        showMessage("Could not rescrape all bets: " + error.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const showPagination = (): boolean => {
    return totalPages > 1;
  };

  const paginationChangeHandler = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value);
  };

  return (
    <LoadingOverlay active={loading} spinner text="Loading Bets...">
      <div className="full-push-background-light with-navbar">
        <div className="page-container">
          {requiredScrapes.map((betSource) => (
            <AppAlert severity="warning" key={betSource}>
              You haven't scraped {betSourceReadableNames[betSource]} within the
              last week, please scrape now!
            </AppAlert>
          ))}
          <div className="page-title-and-buttons">
            <div className="page-title-left-aligned">View Bets</div>
            {Object.keys(groupedBets).length !== 0 && (
              <div style={{ display: "flex", gap: "10px" }}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  <TooltipIconButton
                    title={showLogin ? "Close Login" : "Refresh Your Bets"}
                    onClick={() => setShowLogin(!showLogin)}
                    icon={showLogin ? "close" : "refresh"}
                  />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  <CSVLink
                    filename={createCSVFilename()}
                    data={createCSVContent(groupedBets, groupedOdds)}
                  >
                    <Tooltip title={"Download Your Bets to CSV"}>
                      <DownloadIcon sx={{ color: "black" }} />
                    </Tooltip>
                  </CSVLink>
                </Box>

                <DeveloperRoute>
                  <Button variant="contained" onClick={() => rescrapeAll()}>
                    Rescrape All Bets
                  </Button>
                </DeveloperRoute>
              </div>
            )}
          </div>
          {showLogin && (
            <Box
              sx={{
                backgroundColor: "white",
                padding: 2,
                borderRadius: 1,
                marginBottom: 2,
              }}
            >
              <BetSourceScrapeTitle
                alertsEnabled={alertsEnabled}
                betSource={BetSource.WINNER_7}
              />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  scrapeBets("WINNER_7");
                }}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <input
                    type="text"
                    value={winner7Username}
                    onChange={(e) => setWinner7Username(e.target.value)}
                    placeholder="Username"
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <input
                    type="password"
                    value={winner7Password}
                    onChange={(e) => setWinner7Password(e.target.value)}
                    placeholder="Password"
                  />
                </Box>
                <Button variant="contained" type="submit">
                  Get Winner 7
                </Button>
              </form>
              <p style={{ marginTop: "8px" }}>
                We will never store your Winner7 credentials
              </p>
            </Box>
          )}
          {showLogin && (
            <Box
              sx={{
                backgroundColor: "white",
                padding: 2,
                borderRadius: 1,
                marginBottom: 2,
              }}
            >
              <BetSourceScrapeTitle
                alertsEnabled={alertsEnabled}
                betSource={BetSource.BETFAIR}
              />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  scrapeBets("BETFAIR");
                }}
              >
                <Box sx={{ marginBottom: 2 }}>
                  <input
                    type="text"
                    value={betfairUsername}
                    onChange={(e) => setBetfairUsername(e.target.value)}
                    placeholder="Username"
                  />
                </Box>
                <Box sx={{ marginBottom: 2 }}>
                  <input
                    type="password"
                    value={betfairPassword}
                    onChange={(e) => setBetfairPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <span className="italic grey" style={{ paddingLeft: "1em" }}>
                    Add any required 2FA code to the end of your password.
                  </span>
                </Box>
                <Button variant="contained" type="submit">
                  Get Betfair
                </Button>
              </form>
              <p style={{ marginTop: "8px" }}>
                We will never store your Betfair credentials
              </p>
            </Box>
          )}

          {!loading && (
            <div>
              {Object.keys(groupedBets).length !== 0 ? (
                <>
                  <div className="page-title-left-aligned">Bets</div>
                  <Box sx={{ width: "100%", backgroundColor: "white" }}>
                    <BetHistoryDisplay
                      groupedBets={groupedBets}
                      groupedOdds={groupedOdds}
                      matches={matches}
                      linksBySourceEventId={linksBySourceEventId}
                      onBetsUpdated={handleBetsUpdated}
                      setLoading={setLoading}
                    />
                  </Box>
                  {showPagination() && (
                    <div style={{ paddingTop: "1em", paddingBottom: "1em" }}>
                      <PaginationButtons
                        page={currentPage}
                        pageCount={totalPages}
                        onChange={paginationChangeHandler}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div>Loading bets...</div>
              )}
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
}
