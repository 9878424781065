import { Button, Input } from "@mui/material";
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { nanSafeWithDefault, round } from "../../../types/util-functions";

const standardStyle = {
  display: "flex",
  flexDirection: "row" as const,
  alignItems: "center",
  justifyContent: "space-between",
  padding: "2px",
  gap: "10px",
  width: "100%",
};

const standardComponentsStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#f8f8ff",
  border: "1px solid #dddddd",
  borderRadius: "3px",
  padding: "0px",
};

const standardTextFieldStyle = {
  border: "0px",
  paddingLeft: "1em",
  borderRight: "1px solid #dddddd",
  borderRadius: "3px",
  height: "30px",
  maxHeight: "30px",
  width: "80px",
  backgroundColor: "#FFFFFF",
};

const standardButtonStyle = {
  width: "16px",
  padding: "0px",
  minWidth: "16px",
  minHeight: "14.5px",
  maxHeight: "14.5px",
  verticalAlign: "middle",
  fontSize: "5px",
  color: "#444444",
};

export const tallNumberSelectorTextField = {
  height: "54px",
  minHeight: "54px",
};

export const tallNumberSelectorButtons = {
  maxHeight: "26.5px",
  minHeight: "26.5px",
};

export const columnNumberSelector = {
  flexDirection: "column" as const,
  padding: "1em",
  width: "100%",
  alignItems: "baseline",
};

export const medianNumberSelector = {
  height: "32px",
  maxHeight: "32px",
};

export const matchFormatNumberSelector = {
  padding: "1em",
};

interface Props {
  min: number;
  max: number;
  initial: number;
  onValid: (valid: number) => void;
  label?: string;
  step?: number;
  decimalPlaces?: number;
  disabled?: boolean;
  onInvalid?: (invalid: number) => void;
  onManualEdit?: () => void;
  onManualEditFinished?: () => void;
  style?: CSSProperties;
  componentsStyle?: CSSProperties;
  textFieldStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  testId?: string;
  children?: ReactNode;
}

export default function NumberSelector({
  min,
  max,
  initial,
  onValid,
  label = "",
  step = 1,
  decimalPlaces = 0,
  disabled = false,
  onInvalid = () => {},
  onManualEdit = () => {},
  onManualEditFinished = () => {},
  style = null,
  componentsStyle = null,
  textFieldStyle = null,
  buttonStyle = null,
  testId = "default-test-id",
  children,
}: Readonly<Props>): React.JSX.Element {
  const isValid = (value: number) => {
    try {
      return value >= min && value <= max;
    } catch (error) {
      return false;
    }
  };

  const [value, setValue] = useState<number>(
    Number(round(initial, decimalPlaces))
  );
  const [previousValue, setPreviousValue] = useState<number>(
    Number(round(initial, decimalPlaces))
  );
  const [previousMin, setPreviousMin] = useState<number>(min);
  const [previousMax, setPreviousMax] = useState<number>(max);
  const [valid, setValid] = useState<boolean>(isValid(initial));
  const [manualEditing, setManualEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(null);
  const [debounceMs, setDebounceMs] = useState<number>(200);

  const [validValueToDebounce, setValidValueToDebounce] =
    useState<number>(null);
  const [invalidValueToDebounce, setInvalidValueToDebounce] =
    useState<number>(null);

  useEffect(() => {
    if (validValueToDebounce !== null) {
      const timer = setTimeout(() => {
        onValid(validValueToDebounce);
        setValidValueToDebounce(null);
      }, debounceMs);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [validValueToDebounce, debounceMs, onValid]);

  useEffect(() => {
    if (invalidValueToDebounce !== null) {
      const timer = setTimeout(() => {
        onInvalid(invalidValueToDebounce);
        setInvalidValueToDebounce(null);
      }, debounceMs);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [invalidValueToDebounce, debounceMs, onInvalid]);

  useEffect(() => {
    setValue(Number(round(initial, decimalPlaces)));
  }, [initial, decimalPlaces]);

  useEffect(() => {
    let newValueValid: boolean;
    try {
      newValueValid = value >= min && value <= max;
    } catch (error) {
      newValueValid = false;
    }

    if (value !== previousValue || min !== previousMin || max !== previousMax) {
      setPreviousValue(value);
      setPreviousMax(max);
      setPreviousMin(min);

      if (value !== previousValue || newValueValid !== valid) {
        setValid(newValueValid);
        if (newValueValid) {
          setValidValueToDebounce(value);
        } else {
          setInvalidValueToDebounce(value);
        }
      }
    }
  }, [
    valid,
    value,
    previousValue,
    previousMin,
    previousMax,
    decimalPlaces,
    max,
    min,
    setValidValueToDebounce,
    setInvalidValueToDebounce,
  ]);

  const increment = () => {
    onChange(isValid(value) ? Number(value) + step : min);
  };

  const decrement = () => {
    onChange(isValid(value) ? Number(value) - step : max);
  };

  const onChange = (newValue: any) => {
    setValue(sanitize(newValue));
  };

  const sanitize = (value: any) => {
    return value !== null && value !== undefined
      ? Math.max(
          min,
          Math.min(
            max,
            nanSafeWithDefault(Number(round(value, decimalPlaces)), min)
          )
        )
      : min;
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.target.select();
    event.target.addEventListener(
      "keypress",
      (keyboardEvent: KeyboardEvent) => {
        if (keyboardEvent.key === "Enter") {
          keyboardEvent.preventDefault();
          event.target.blur();
        }
      }
    );
  };

  const switchToManualEditing = () => {
    onManualEdit();
    setEditValue("" + value);
    setManualEditing(true);
  };

  const finishManualEditing = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setManualEditing(false);
    onChange(event.target.value);
    onManualEditFinished();
  };

  return (
    <div style={{ ...standardStyle, ...style }}>
      <div>{label}</div>

      {children}

      <div
        style={{
          ...standardComponentsStyle,
          ...componentsStyle,
        }}
      >
        {!manualEditing && (
          <Input
            data-testid={testId}
            sx={{
              ...standardTextFieldStyle,
              ...textFieldStyle,
              ...(!valid && {
                backgroundColor: "#d89292",
              }),
            }}
            value={value}
            onFocusCapture={() => switchToManualEditing()}
            onClick={() => switchToManualEditing()}
            onChange={(event) => {
              setDebounceMs(200);
              onChange(event.target.value);
            }}
            disabled={disabled}
            disableUnderline={true}
          />
        )}

        {manualEditing && (
          <Input
            value={editValue}
            autoFocus={true}
            onFocus={(event) => handleFocus(event)}
            onChange={(event) => {
              setDebounceMs(200);
              setEditValue(event.target.value);
            }}
            onBlur={(event) => {
              setDebounceMs(0);
              finishManualEditing(event);
            }}
            disabled={disabled}
            sx={{
              ...standardTextFieldStyle,
              ...textFieldStyle,
            }}
            disableUnderline={true}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
          }}
        >
          <Button
            size="small"
            data-testid={testId + "-increase"}
            onClick={() => increment()}
            disabled={disabled || value === max}
            title="Decrease"
            sx={{ ...standardButtonStyle, ...buttonStyle }}
          >
            ▲
          </Button>
          <div
            style={{
              padding: "1px 0px 0px 0px",
              minHeight: "1px",
              margin: "0",
              backgroundColor: (componentsStyle?.backgroundColor ||
                "#dddddd") as any,
            }}
          />
          <Button
            size="small"
            data-testid={testId + "-decrease"}
            onClick={() => decrement()}
            disabled={disabled || value <= min}
            title="Decrease"
            sx={{ ...standardButtonStyle, ...buttonStyle }}
          >
            ▼
          </Button>
        </div>
      </div>
    </div>
  );
}
