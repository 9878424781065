import { Box, Checkbox, Tab, Tabs } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { UserPreferences } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import {
  BattingAttackData,
  BattingAttackModuleType,
  battingAttackDataProperties,
  battingAttackModuleNames,
  battingAttackModuleTooltips,
} from "../../types/simulator/modules/batting-attack-modules";
import {
  BowlingAttackData,
  BowlingAttackModuleType,
  bowlingAttackDataProperties,
  bowlingAttackModuleNames,
  bowlingAttackModuleTooltips,
} from "../../types/simulator/modules/bowling-attack-modules";
import {
  ExtrasData,
  ExtrasModuleType,
  extrasDataProperties,
  extrasModuleCanBeDisabled,
  extrasModuleNames,
  extrasModuleTooltips,
} from "../../types/simulator/modules/extras-modules";
import {
  OutcomeAdjustData,
  OutcomeAdjustModuleType,
  outcomeAdjustDataProperties,
  outcomeAdjustModuleNames,
  outcomeAdjustModuleTooltips,
} from "../../types/simulator/modules/outcome-adjust-modules";
import {
  PercentAdjustData,
  PercentAdjustModuleType,
  percentAdjustDataProperties,
  percentAdjustModuleCanBeDisabled,
  percentAdjustModuleNames,
  percentAdjustModuleTooltips,
} from "../../types/simulator/modules/percent-adjust-modules";
import {
  PushAdjustData,
  PushAdjustModuleType,
  pushAdjustDataProperties,
  pushAdjustModuleNames,
  pushAdjustModuleTooltips,
} from "../../types/simulator/modules/push-adjust-modules";
import {
  StrikeRateAndWicketPercentData,
  StrikeRateAndWicketPercentModuleType,
  strikeRateAndWicketPercentDataProperties,
  strikeRateAndWicketPercentModuleNames,
  strikeRateAndWicketPercentModuleTooltips,
} from "../../types/simulator/modules/strike-rate-and-wicket-percent-modules";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { TabPanel } from "./../match-page/scorecard/tab-panel";

import { BattingAttackModuleSettingsModal } from "./batting-attack-module-settings-modal";
import { BowlingAttackModuleSettingsModal } from "./bowling-attack-module-settings-modal";
import { ExtrasModuleSettingsModal } from "./extras-module-settings-modal";
import { OutcomeAdjustModuleSettingsModal } from "./outcome-adjust-module-settings-modal";
import { PercentAdjustModuleSettingsModal } from "./percent-adjust-module-settings-modal";
import { PushAdjustModuleSettingsModal } from "./push-adjust-module-settings-modal";
import StrikeRateAndWicketPercentModuleSettingsModal from "./strike-rate-and-wicket-percent-module-settings-modal";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (settings: UserPreferences) => void;
}

interface State {
  settings: UserPreferences;
  numberOfSimulationsValid: boolean;
  numberOfScenarioSimulationsValid: boolean;
  numberOfRecordedSimulationsValid: boolean;
  runsLineMarginValid: boolean;
  runsLineUpperBoundValid: boolean;
  runsLineLowerBoundValid: boolean;
  runsLineStepValid: boolean;
  thoughtWicketMultiplierValid: boolean;
  pushAdjustModuleSettingsModalOpen: boolean;
  pushAdjustModuleModalType: PushAdjustModuleType;
  pushAdjustModuleModalData: PushAdjustData;
  percentAdjustModuleSettingsModalOpen: boolean;
  percentAdjustModuleModalType: PercentAdjustModuleType;
  percentAdjustModuleModalData: PercentAdjustData;
  outcomeAdjustModuleSettingsModalOpen: boolean;
  outcomeAdjustModuleModalType: OutcomeAdjustModuleType;
  outcomeAdjustModuleModalData: OutcomeAdjustData;
  extrasModuleSettingsModalOpen: boolean;
  extrasModuleModalType: ExtrasModuleType;
  extrasModuleModalData: ExtrasData;
  strikeRateAndWicketPercentModuleSettingsModalOpen: boolean;
  strikeRateAndWicketPercentModuleModalType: StrikeRateAndWicketPercentModuleType;
  strikeRateAndWicketPercentModuleModalData: StrikeRateAndWicketPercentData;
  battingAttackModuleSettingsModalOpen: boolean;
  battingAttackModuleModalType: BattingAttackModuleType;
  battingAttackModuleModalData: BattingAttackData;
  bowlingAttackModuleSettingsModalOpen: boolean;
  bowlingAttackModuleModalType: BowlingAttackModuleType;
  bowlingAttackModuleModalData: BowlingAttackData;
  selectedTab: number;
}

export class SimulatorSettingsModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      numberOfSimulationsValid: true,
      numberOfScenarioSimulationsValid: true,
      numberOfRecordedSimulationsValid: true,
      runsLineMarginValid: true,
      runsLineUpperBoundValid: true,
      runsLineLowerBoundValid: true,
      runsLineStepValid: true,
      thoughtWicketMultiplierValid: true,
      pushAdjustModuleSettingsModalOpen: false,
      pushAdjustModuleModalType: null,
      pushAdjustModuleModalData: null,
      percentAdjustModuleSettingsModalOpen: false,
      percentAdjustModuleModalType: null,
      percentAdjustModuleModalData: null,
      outcomeAdjustModuleSettingsModalOpen: false,
      outcomeAdjustModuleModalType: null,
      outcomeAdjustModuleModalData: null,
      extrasModuleSettingsModalOpen: false,
      extrasModuleModalType: null,
      extrasModuleModalData: null,
      strikeRateAndWicketPercentModuleSettingsModalOpen: false,
      strikeRateAndWicketPercentModuleModalType: null,
      strikeRateAndWicketPercentModuleModalData: null,
      battingAttackModuleSettingsModalOpen: false,
      battingAttackModuleModalType: null,
      battingAttackModuleModalData: null,
      bowlingAttackModuleSettingsModalOpen: false,
      bowlingAttackModuleModalType: null,
      bowlingAttackModuleModalData: null,
      selectedTab: 0,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe(
        (settings: UserPreferences) =>
          this.setState({
            settings,
            numberOfSimulationsValid: true,
          })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private invalid(): boolean {
    return !(
      this.state.numberOfSimulationsValid &&
      this.state.numberOfScenarioSimulationsValid &&
      this.state.numberOfRecordedSimulationsValid &&
      this.state.runsLineLowerBoundValid &&
      this.state.runsLineUpperBoundValid &&
      this.state.runsLineMarginValid &&
      this.state.runsLineStepValid &&
      this.state.thoughtWicketMultiplierValid
    );
  }

  private updatePushAdjustModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.pushAdjustModules.get(
        PushAdjustModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updatePushAdjustModuleData(newModuleData, moduleType);
  }

  private updatePercentAdjustModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.percentAdjustModules.get(
        PercentAdjustModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updatePercentAdjustModuleData(newModuleData, moduleType);
  }

  private updateOutcomeAdjustModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.outcomeAdjustModules.get(
        OutcomeAdjustModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updateOutcomeAdjustModuleData(newModuleData, moduleType);
  }

  private updateExtrasModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.extrasModules.get(ExtrasModuleType[moduleType]),
      [property]: newValue,
    };
    this.updateExtrasModuleData(newModuleData, moduleType);
  }

  private updateStrikeRateAndWicketPercentModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.strikeRateAndWicketPercentModules.get(
        StrikeRateAndWicketPercentModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updateStrikeRateAndWicketPercentModuleData(newModuleData, moduleType);
  }

  private updateBattingAttackModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.battingAttackModules.get(
        BattingAttackModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updateBattingAttackModuleData(newModuleData, moduleType);
  }

  private updateBowlingAttackModuleProperty(
    property: string,
    newValue: any,
    moduleType: string
  ) {
    const newModuleData = {
      ...this.state.settings.bowlingAttackModules.get(
        BowlingAttackModuleType[moduleType]
      ),
      [property]: newValue,
    };
    this.updateBowlingAttackModuleData(newModuleData, moduleType);
  }

  private updatePushAdjustModuleData(
    newValue: PushAdjustData,
    moduleType: string
  ) {
    const newPushAdjustModules: Map<PushAdjustModuleType, PushAdjustData> =
      this.state.settings.pushAdjustModules;
    newPushAdjustModules.set(PushAdjustModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      pushAdjustModules: newPushAdjustModules,
    };
    this.setState({ settings, pushAdjustModuleSettingsModalOpen: false });
  }

  private updatePercentAdjustModuleData(
    newValue: PercentAdjustData,
    moduleType: string
  ) {
    const newPercentAdjustModules: Map<
      PercentAdjustModuleType,
      PercentAdjustData
    > = this.state.settings.percentAdjustModules;
    newPercentAdjustModules.set(PercentAdjustModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      percentAdjustModules: newPercentAdjustModules,
    };
    this.setState({ settings, percentAdjustModuleSettingsModalOpen: false });
  }

  private updateOutcomeAdjustModuleData(
    newValue: OutcomeAdjustData,
    moduleType: string
  ) {
    const newOutcomeAdjustModules: Map<
      OutcomeAdjustModuleType,
      OutcomeAdjustData
    > = this.state.settings.outcomeAdjustModules;
    newOutcomeAdjustModules.set(OutcomeAdjustModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      outcomeAdjustModules: newOutcomeAdjustModules,
    };
    this.setState({ settings, outcomeAdjustModuleSettingsModalOpen: false });
  }

  private updateExtrasModuleData(newValue: ExtrasData, moduleType: string) {
    const newExtrasModules: Map<ExtrasModuleType, ExtrasData> =
      this.state.settings.extrasModules;
    newExtrasModules.set(ExtrasModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      extrasModules: newExtrasModules,
    };
    this.setState({ settings, extrasModuleSettingsModalOpen: false });
  }

  private updateStrikeRateAndWicketPercentModuleData(
    newValue: StrikeRateAndWicketPercentData,
    moduleType: string
  ) {
    const newStrikeRateAndWicketPercentModules: Map<
      StrikeRateAndWicketPercentModuleType,
      StrikeRateAndWicketPercentData
    > = this.state.settings.strikeRateAndWicketPercentModules;
    newStrikeRateAndWicketPercentModules.set(
      StrikeRateAndWicketPercentModuleType[moduleType],
      newValue
    );

    const settings = {
      ...this.state.settings,
      strikeRateAndWicketPercentModules: newStrikeRateAndWicketPercentModules,
    };
    this.setState({
      settings,
      strikeRateAndWicketPercentModuleSettingsModalOpen: false,
    });
  }

  private updateBattingAttackModuleData(
    newValue: BattingAttackData,
    moduleType: string
  ) {
    const newBattingAttackModules: Map<
      BattingAttackModuleType,
      BattingAttackData
    > = this.state.settings.battingAttackModules;
    newBattingAttackModules.set(BattingAttackModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      battingAttackModules: newBattingAttackModules,
    };
    this.setState({ settings, battingAttackModuleSettingsModalOpen: false });
  }

  private updateBowlingAttackModuleData(
    newValue: BowlingAttackData,
    moduleType: string
  ) {
    const newBowlingAttackModules: Map<
      BowlingAttackModuleType,
      BowlingAttackData
    > = this.state.settings.bowlingAttackModules;
    newBowlingAttackModules.set(BowlingAttackModuleType[moduleType], newValue);

    const settings = {
      ...this.state.settings,
      bowlingAttackModules: newBowlingAttackModules,
    };
    this.setState({ settings, bowlingAttackModuleSettingsModalOpen: false });
  }

  private openPushAdjustModuleSettingsModal(moduleType: PushAdjustModuleType) {
    this.setState({
      pushAdjustModuleSettingsModalOpen: true,
      pushAdjustModuleModalType: moduleType,
      pushAdjustModuleModalData:
        this.state.settings.pushAdjustModules.get(moduleType),
    });
  }

  private openPercentAdjustModuleSettingsModal(
    moduleType: PercentAdjustModuleType
  ) {
    this.setState({
      percentAdjustModuleSettingsModalOpen: true,
      percentAdjustModuleModalType: moduleType,
      percentAdjustModuleModalData:
        this.state.settings.percentAdjustModules.get(moduleType),
    });
  }

  private openOutcomeAdjustModuleSettingsModal(
    moduleType: OutcomeAdjustModuleType
  ) {
    this.setState({
      outcomeAdjustModuleSettingsModalOpen: true,
      outcomeAdjustModuleModalType: moduleType,
      outcomeAdjustModuleModalData:
        this.state.settings.outcomeAdjustModules.get(moduleType),
    });
  }

  private openExtrasModuleSettingsModal(moduleType: ExtrasModuleType) {
    this.setState({
      extrasModuleSettingsModalOpen: true,
      extrasModuleModalType: moduleType,
      extrasModuleModalData: this.state.settings.extrasModules.get(moduleType),
    });
  }

  private openStrikeRateAndWicketPercentModuleSettingsModal(
    moduleType: StrikeRateAndWicketPercentModuleType
  ) {
    this.setState({
      strikeRateAndWicketPercentModuleSettingsModalOpen: true,
      strikeRateAndWicketPercentModuleModalType: moduleType,
      strikeRateAndWicketPercentModuleModalData:
        this.state.settings.strikeRateAndWicketPercentModules.get(moduleType),
    });
  }

  private openBowlingAttackModuleSettingsModal(
    moduleType: BowlingAttackModuleType
  ) {
    this.setState({
      bowlingAttackModuleSettingsModalOpen: true,
      bowlingAttackModuleModalType: moduleType,
      bowlingAttackModuleModalData:
        this.state.settings.bowlingAttackModules.get(moduleType),
    });
  }

  private openBattingAttackModuleSettingsModal(
    moduleType: BattingAttackModuleType
  ) {
    this.setState({
      battingAttackModuleSettingsModalOpen: true,
      battingAttackModuleModalType: moduleType,
      battingAttackModuleModalData:
        this.state.settings.battingAttackModules.get(moduleType),
    });
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Simulator Settings"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.settings)}
        proceedText="OK"
        colour="#535455"
      >
        {this.state.settings && (
          <div className="simulator-settings-modal-body">
            <NumberSelector
              label="Number of Simulations"
              min={0}
              max={1000000}
              initial={this.state.settings.numberOfSimulations}
              onValid={(valid) =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    numberOfSimulations: valid,
                  },
                  numberOfSimulationsValid: true,
                })
              }
              onInvalid={() =>
                this.setState({ numberOfSimulationsValid: false })
              }
            />

            <NumberSelector
              label="Number of Recorded Simulations"
              min={0}
              max={100}
              initial={this.state.settings.numberOfRecordedSimulations}
              onValid={(valid) =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    numberOfRecordedSimulations: valid,
                  },
                  numberOfRecordedSimulationsValid: true,
                })
              }
              onInvalid={() =>
                this.setState({ numberOfRecordedSimulationsValid: false })
              }
            />

            <NumberSelector
              label="Number of Simulations for Next Ball Scenarios"
              min={0}
              max={1000000}
              initial={this.state.settings.numberOfScenarioSimulations}
              onValid={(valid) =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    numberOfScenarioSimulations: valid,
                  },
                  numberOfScenarioSimulationsValid: true,
                })
              }
              onInvalid={() =>
                this.setState({ numberOfScenarioSimulationsValid: false })
              }
            />

            <div className="checkbox-and-label-stretch">
              <div>
                Regress Ground Stats to Country Average when lacking confidence?
              </div>
              <Checkbox
                checked={this.state.settings.regressToCountryStats || false}
                onChange={(event) =>
                  this.setState({
                    settings: {
                      ...this.state.settings,
                      regressToCountryStats: event.target.checked,
                    },
                  })
                }
                disabled={false}
              />
            </div>

            <hr />
            <div>Runs Line Settings</div>
            <NumberSelector
              label="Lower Bound"
              min={0}
              max={
                this.state.settings.runsLineUpperBound -
                this.state.settings.runsLineStep
              }
              step={this.state.settings.runsLineStep}
              initial={this.state.settings.runsLineLowerBound}
              onValid={(valid) =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    runsLineLowerBound: valid,
                  },
                  runsLineLowerBoundValid: true,
                })
              }
              onInvalid={() =>
                this.setState({ runsLineLowerBoundValid: false })
              }
            />
            <NumberSelector
              label="Upper Bound"
              min={
                this.state.settings.runsLineLowerBound +
                this.state.settings.runsLineStep
              }
              max={10000000000000}
              step={this.state.settings.runsLineStep}
              initial={this.state.settings.runsLineUpperBound}
              onValid={(valid) =>
                this.setState({
                  settings: {
                    ...this.state.settings,
                    runsLineUpperBound: valid,
                  },
                  runsLineUpperBoundValid: true,
                })
              }
              onInvalid={() =>
                this.setState({ runsLineUpperBoundValid: false })
              }
            />
            <NumberSelector
              label="Margin"
              min={0}
              max={10000000000000}
              step={1}
              initial={this.state.settings.runsLineMargin}
              onValid={(valid) =>
                this.setState({
                  settings: { ...this.state.settings, runsLineMargin: valid },
                  runsLineMarginValid: true,
                })
              }
              onInvalid={() => this.setState({ runsLineMarginValid: false })}
            />
            <NumberSelector
              label="Step"
              min={1}
              max={50}
              initial={this.state.settings.runsLineStep}
              onValid={(valid) =>
                this.setState({
                  settings: { ...this.state.settings, runsLineStep: valid },
                  runsLineStepValid: true,
                })
              }
              onInvalid={() => this.setState({ runsLineStepValid: false })}
            />

            <hr />
            <div>Randomness Settings</div>
            <NumberSelector
              label="Ground global strike rate random variance"
              min={0}
              max={2}
              step={0.01}
              decimalPlaces={2}
              initial={this.state.settings.randomGroundGlobalStrikeRateVariance}
              onValid={(valid) => {
                this.setState({
                  settings: {
                    ...this.state.settings,
                    randomGroundGlobalStrikeRateVariance: valid,
                  },
                });
              }}
            />

            <NumberSelector
              label="Ground global wicket percent random variance"
              min={0}
              max={2}
              step={0.01}
              decimalPlaces={2}
              initial={
                this.state.settings.randomGroundGlobalWicketPercentVariance
              }
              onValid={(valid) => {
                this.setState({
                  settings: {
                    ...this.state.settings,
                    randomGroundGlobalWicketPercentVariance: valid,
                  },
                });
              }}
            />

            <Box>
              <Tabs
                value={this.state.selectedTab}
                onChange={(value, newValue) =>
                  this.setState({ selectedTab: newValue })
                }
                aria-label="Simulator Settings Tabs"
                className="simulator-settings-tab-panel"
                variant="scrollable"
              >
                <Tab
                  label="Push"
                  id="sim-settings-tab-0"
                  aria-controls="sim-settings-tabpanel-0"
                />
                <Tab
                  label="Percents"
                  id="sim-settings-tab-1"
                  aria-controls="sim-settings-tabpanel-1"
                />
                <Tab
                  label="Extras"
                  id="sim-settings-tab-2"
                  aria-controls="sim-settings-tabpanel-2"
                />
                <Tab
                  label="Strike Rate and Wicket %"
                  id="sim-settings-tab-3"
                  aria-controls="sim-settings-tabpanel-3"
                />
                <Tab
                  label="Batting Attack"
                  id="sim-settings-tab-4"
                  aria-controls="sim-settings-tabpanel-4"
                />
                <Tab
                  label="Bowling Attack"
                  id="sim-settings-tab-5"
                  aria-controls="sim-settings-tabpanel-4"
                />
                <Tab
                  label="Outcome"
                  id="sim-settings-tab-6"
                  aria-controls="sim-settings-tabpanel-5"
                />
              </Tabs>
            </Box>

            <TabPanel value={this.state.selectedTab} index={0}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Push Adjustment Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(PushAdjustModuleType).map((moduleType) => {
                  const data = this.state.settings.pushAdjustModules.get(
                    PushAdjustModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={pushAdjustModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openPushAdjustModuleSettingsModal(
                              PushAdjustModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            pushAdjustDataProperties[moduleType].length === 0
                          }
                        />
                        {pushAdjustModuleNames[moduleType]}
                      </div>
                      <div>
                        <Checkbox
                          checked={data.enabled}
                          onChange={(event) =>
                            this.updatePushAdjustModuleProperty(
                              "enabled",
                              event.target.checked,
                              moduleType
                            )
                          }
                          disabled={false}
                        />
                      </div>
                    </div>
                  );
                })}
                <PushAdjustModuleSettingsModal
                  open={this.state.pushAdjustModuleSettingsModalOpen}
                  moduleType={this.state.pushAdjustModuleModalType}
                  initialModuleData={this.state.pushAdjustModuleModalData}
                  onCancel={() =>
                    this.setState({ pushAdjustModuleSettingsModalOpen: false })
                  }
                  onProceed={(data, type) =>
                    this.updatePushAdjustModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={1}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Percent Adjustment Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(PercentAdjustModuleType).map((moduleType) => {
                  const data = this.state.settings.percentAdjustModules.get(
                    PercentAdjustModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={percentAdjustModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openPercentAdjustModuleSettingsModal(
                              PercentAdjustModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            percentAdjustDataProperties[moduleType].length === 0
                          }
                        />
                        {percentAdjustModuleNames[moduleType]}
                      </div>
                      <Checkbox
                        checked={data.enabled}
                        onChange={(event) =>
                          this.updatePercentAdjustModuleProperty(
                            "enabled",
                            event.target.checked,
                            moduleType
                          )
                        }
                        disabled={!percentAdjustModuleCanBeDisabled[moduleType]}
                      />
                    </div>
                  );
                })}
                <PercentAdjustModuleSettingsModal
                  open={this.state.percentAdjustModuleSettingsModalOpen}
                  moduleType={this.state.percentAdjustModuleModalType}
                  initialModuleData={this.state.percentAdjustModuleModalData}
                  onCancel={() =>
                    this.setState({
                      percentAdjustModuleSettingsModalOpen: false,
                    })
                  }
                  onProceed={(data, type) =>
                    this.updatePercentAdjustModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={2}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Extras Calculation Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(ExtrasModuleType).map((moduleType) => {
                  const data = this.state.settings.extrasModules.get(
                    ExtrasModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={extrasModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openExtrasModuleSettingsModal(
                              ExtrasModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            extrasDataProperties[moduleType].length === 0
                          }
                        />
                        {extrasModuleNames[moduleType]}
                      </div>
                      <Checkbox
                        checked={data.enabled}
                        onChange={(event) =>
                          this.updateExtrasModuleProperty(
                            "enabled",
                            event.target.checked,
                            moduleType
                          )
                        }
                        disabled={!extrasModuleCanBeDisabled[moduleType]}
                      />
                    </div>
                  );
                })}
                <ExtrasModuleSettingsModal
                  open={this.state.extrasModuleSettingsModalOpen}
                  moduleType={this.state.extrasModuleModalType}
                  initialModuleData={this.state.extrasModuleModalData}
                  onCancel={() =>
                    this.setState({ extrasModuleSettingsModalOpen: false })
                  }
                  onProceed={(data, type) =>
                    this.updateExtrasModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={3}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Strike Rate and Wicket Percent Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(StrikeRateAndWicketPercentModuleType).map(
                  (moduleType) => {
                    const data =
                      this.state.settings.strikeRateAndWicketPercentModules.get(
                        StrikeRateAndWicketPercentModuleType[moduleType]
                      );
                    return (
                      <div
                        key={moduleType}
                        className="simulator-settings-modal-module"
                      >
                        <div className="simulator-settings-modal-module-buttons">
                          <TooltipIconButton
                            title={
                              strikeRateAndWicketPercentModuleTooltips[
                                moduleType
                              ]
                            }
                            onClick={() =>
                              this.openStrikeRateAndWicketPercentModuleSettingsModal(
                                StrikeRateAndWicketPercentModuleType[moduleType]
                              )
                            }
                            icon={"settings"}
                            disabled={
                              strikeRateAndWicketPercentDataProperties[
                                moduleType
                              ].length === 0
                            }
                          />
                          {strikeRateAndWicketPercentModuleNames[moduleType]}
                        </div>
                        <Checkbox
                          checked={data.enabled}
                          onChange={(event) =>
                            this.updateStrikeRateAndWicketPercentModuleProperty(
                              "enabled",
                              event.target.checked,
                              moduleType
                            )
                          }
                          disabled={false}
                        />
                      </div>
                    );
                  }
                )}
                <StrikeRateAndWicketPercentModuleSettingsModal
                  open={
                    this.state.strikeRateAndWicketPercentModuleSettingsModalOpen
                  }
                  moduleType={
                    this.state.strikeRateAndWicketPercentModuleModalType
                  }
                  initialModuleData={
                    this.state.strikeRateAndWicketPercentModuleModalData
                  }
                  onCancel={() =>
                    this.setState({
                      strikeRateAndWicketPercentModuleSettingsModalOpen: false,
                    })
                  }
                  onProceed={(data, type) =>
                    this.updateStrikeRateAndWicketPercentModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={4}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Batting Attack Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(BattingAttackModuleType).map((moduleType) => {
                  const data = this.state.settings.battingAttackModules.get(
                    BattingAttackModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={battingAttackModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openBattingAttackModuleSettingsModal(
                              BattingAttackModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            battingAttackDataProperties[moduleType].length === 0
                          }
                        />
                        {battingAttackModuleNames[moduleType]}
                      </div>
                      <Checkbox
                        checked={data.enabled}
                        onChange={(event) =>
                          this.updateBattingAttackModuleProperty(
                            "enabled",
                            event.target.checked,
                            moduleType
                          )
                        }
                        disabled={false}
                      />
                    </div>
                  );
                })}
                <BattingAttackModuleSettingsModal
                  open={this.state.battingAttackModuleSettingsModalOpen}
                  moduleType={this.state.battingAttackModuleModalType}
                  initialModuleData={this.state.battingAttackModuleModalData}
                  onCancel={() =>
                    this.setState({
                      battingAttackModuleSettingsModalOpen: false,
                    })
                  }
                  onProceed={(data, type) =>
                    this.updateBattingAttackModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={5}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Bowling Attack Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(BowlingAttackModuleType).map((moduleType) => {
                  const data = this.state.settings.bowlingAttackModules.get(
                    BowlingAttackModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={bowlingAttackModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openBowlingAttackModuleSettingsModal(
                              BowlingAttackModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            bowlingAttackDataProperties[moduleType].length === 0
                          }
                        />
                        {bowlingAttackModuleNames[moduleType]}
                      </div>
                      <Checkbox
                        checked={data.enabled}
                        onChange={(event) =>
                          this.updateBowlingAttackModuleProperty(
                            "enabled",
                            event.target.checked,
                            moduleType
                          )
                        }
                        disabled={false}
                      />
                    </div>
                  );
                })}
                <BowlingAttackModuleSettingsModal
                  open={this.state.bowlingAttackModuleSettingsModalOpen}
                  moduleType={this.state.bowlingAttackModuleModalType}
                  initialModuleData={this.state.bowlingAttackModuleModalData}
                  onCancel={() =>
                    this.setState({
                      bowlingAttackModuleSettingsModalOpen: false,
                    })
                  }
                  onProceed={(data, type) =>
                    this.updateBowlingAttackModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>

            <TabPanel value={this.state.selectedTab} index={6}>
              <div>
                <div className="simulator-settings-modal-module">
                  <div>Outcome Adjustment Modules</div>
                  <div>Enabled</div>
                </div>
                {Object.keys(OutcomeAdjustModuleType).map((moduleType) => {
                  const data = this.state.settings.outcomeAdjustModules.get(
                    OutcomeAdjustModuleType[moduleType]
                  );
                  return (
                    <div
                      key={moduleType}
                      className="simulator-settings-modal-module"
                    >
                      <div className="simulator-settings-modal-module-buttons">
                        <TooltipIconButton
                          title={outcomeAdjustModuleTooltips[moduleType]}
                          onClick={() =>
                            this.openOutcomeAdjustModuleSettingsModal(
                              OutcomeAdjustModuleType[moduleType]
                            )
                          }
                          icon={"settings"}
                          disabled={
                            outcomeAdjustDataProperties[moduleType].length === 0
                          }
                        />
                        {outcomeAdjustModuleNames[moduleType]}
                      </div>
                      <Checkbox
                        checked={data.enabled}
                        onChange={(event) =>
                          this.updateOutcomeAdjustModuleProperty(
                            "enabled",
                            event.target.checked,
                            moduleType
                          )
                        }
                        disabled={false}
                      />
                    </div>
                  );
                })}
                <OutcomeAdjustModuleSettingsModal
                  open={this.state.outcomeAdjustModuleSettingsModalOpen}
                  moduleType={this.state.outcomeAdjustModuleModalType}
                  initialModuleData={this.state.outcomeAdjustModuleModalData}
                  onCancel={() =>
                    this.setState({
                      outcomeAdjustModuleSettingsModalOpen: false,
                    })
                  }
                  onProceed={(data, type) =>
                    this.updateOutcomeAdjustModuleData(data, type)
                  }
                />
              </div>
            </TabPanel>
          </div>
        )}
      </CreationDialog>
    );
  }
}
