import React from "react";

import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { UserPreferences } from "../../types/preferences/preferences";
import { PercentAdjustModuleType } from "../../types/simulator/modules/percent-adjust-modules";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";

import PlayerStrikeRateAndWicketPercentStats from "./player-strike-rate-and-wicket-percent-stats";

type Props = {
  player: Player;
  squad: Squad;
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  userPreferences: UserPreferences;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  matchStats: MatchStatsWrapper;
};

const playerStatsConfig = [
  {
    title: "Global",
    classes: "global-sr-and-wpc",
    strikeRateProperty: "battingGlobalStrikeRateBias",
    wicketPercentProperty: "battingGlobalWicketBias",
    confidenceProperty: "battingGlobalConfidence",
    percentDistributionProperty: "batsmanGlobalPercentDistributionBiasData",
    percentDistributionPropertyName: "Global",
    percentDistributionConfidenceProperties: ["battingGlobalConfidence"],
    matchStrikeRateAdjustmentProperty: "batsmanMatchStrikeRateAdjustments",
    matchWicketPercentAdjustmentProperty:
      "batsmanMatchWicketPercentAdjustments",
  },
  {
    title: "Extreme Pace",
    classes: "extreme-pace",
    strikeRateProperty: "extremePaceStrikeRateBias",
    wicketPercentProperty: "extremePaceWicketBias",
    confidenceProperty: "extremePaceConfidence",
    percentDistributionProperty: "batsmanPacePercentDistributionBiasData",
    percentDistributionPropertyName: "Pace",
    percentDistributionConfidenceProperties: [
      "extremePaceConfidence",
      "normalPaceConfidence",
    ],
    matchStrikeRateAdjustmentProperty: null,
    matchWicketPercentAdjustmentProperty: null,
  },
  {
    title: "Normal Pace",
    classes: "normal-pace",
    strikeRateProperty: "normalPaceStrikeRateBias",
    wicketPercentProperty: "normalPaceWicketBias",
    confidenceProperty: "normalPaceConfidence",
    percentDistributionProperty: null,
    percentDistributionPropertyName: null,
    percentDistributionConfidenceProperties: null,
    matchStrikeRateAdjustmentProperty: null,
    matchWicketPercentAdjustmentProperty: null,
  },
  {
    title: "Wrist Spin",
    classes: "wrist-spin",
    strikeRateProperty: "wristSpinStrikeRateBias",
    wicketPercentProperty: "wristSpinWicketBias",
    confidenceProperty: "wristSpinConfidence",
    percentDistributionProperty: "batsmanSpinPercentDistributionBiasData",
    percentDistributionPropertyName: "Spin",
    percentDistributionConfidenceProperties: [
      "wristSpinConfidence",
      "awaySpinConfidence",
      "intoSpinConfidence",
    ],
    matchStrikeRateAdjustmentProperty: null,
    matchWicketPercentAdjustmentProperty: null,
  },
  {
    title: "Spin Away",
    classes: "spin-away",
    strikeRateProperty: "awaySpinStrikeRateBias",
    wicketPercentProperty: "awaySpinWicketBias",
    confidenceProperty: "awaySpinConfidence",
    percentDistributionProperty: null,
    percentDistributionPropertyName: null,
    percentDistributionConfidenceProperties: null,
    matchStrikeRateAdjustmentProperty: null,
    matchWicketPercentAdjustmentProperty: null,
  },
  {
    title: "Spin Into",
    classes: "spin-into",
    strikeRateProperty: "intoSpinStrikeRateBias",
    wicketPercentProperty: "intoSpinWicketBias",
    confidenceProperty: "intoSpinConfidence",
    percentDistributionProperty: null,
    percentDistributionPropertyName: null,
    percentDistributionConfidenceProperties: null,
    matchStrikeRateAdjustmentProperty: null,
    matchWicketPercentAdjustmentProperty: null,
  },
];

const calculatePercentDistributionConfidence = (
  percentDistributionConfidenceProperties: string[],
  currentStats: PlayerStatsWrapper
) => {
  return percentDistributionConfidenceProperties && currentStats
    ? percentDistributionConfidenceProperties
        .map((property) => currentStats.playerStats[property])
        .reduce((partialSum, a) => partialSum + a, 0)
    : null;
};

export default function BattingStrikeRateAndWicketPercentStats({
  currentStats,
  comparedStats,
  comparedUserName,
  userPreferences,
  onUpdate,
  matchStats,
  player,
  squad,
}: Readonly<Props>): React.JSX.Element {
  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">Bowler Type Strengths</div>
      <div className="batting-stats-modal-6-columns">
        {playerStatsConfig.map(
          ({
            title,
            classes,
            strikeRateProperty,
            wicketPercentProperty,
            confidenceProperty,
            percentDistributionProperty,
            percentDistributionPropertyName,
            percentDistributionConfidenceProperties,
            matchStrikeRateAdjustmentProperty,
            matchWicketPercentAdjustmentProperty,
          }) => (
            <PlayerStrikeRateAndWicketPercentStats
              key={title}
              title={title}
              classes={classes}
              strikeRateProperty={strikeRateProperty}
              wicketPercentProperty={wicketPercentProperty}
              confidenceProperty={confidenceProperty}
              globalDistributionProperty="batsmanGlobalPercentDistributionBiasData"
              globalDistributionConfidence={
                currentStats.playerStats.battingGlobalConfidence
              }
              percentDistributionProperty={percentDistributionProperty}
              percentDistributionPropertyName={percentDistributionPropertyName}
              percentDistributionConfidence={calculatePercentDistributionConfidence(
                percentDistributionConfidenceProperties,
                currentStats
              )}
              currentStats={currentStats}
              comparedStats={comparedStats}
              comparedUserName={comparedUserName}
              userPreferences={userPreferences}
              onUpdate={onUpdate}
              percentAdjustModuleType={
                PercentAdjustModuleType.BATSMAN_PERCENT_BIAS
              }
              matchStats={matchStats}
              matchStrikeRateAdjustmentProperty={
                matchStrikeRateAdjustmentProperty
              }
              matchWicketPercentAdjustmentProperty={
                matchWicketPercentAdjustmentProperty
              }
              player={player}
              squad={squad}
            />
          )
        )}
      </div>
    </div>
  );
}
